export const USD_CURRENCY = 'USD';
export const CAD_CURRENCY = 'CAD';
export const USD_CURRENCY_SYMBOL = 'US$';
export const CAD_CURRENCY_SYMBOL = 'CA$';
export const CURRENCY_COOKIE = 'currency';
export const USD_RATE = 1;

export const formatCurrencyV2 = (
  price,
  priceRate,
  currency,
  useMargin = true,
  includeDecimals = true // New parameter to control decimal inclusion
) => {
  let marginRate = 1;
  if (useMargin) {
    marginRate = currency.toUpperCase() === CAD_CURRENCY ? 1.01 : 1;
  }
  const symbol =
    currency.toUpperCase() === USD_CURRENCY
      ? USD_CURRENCY_SYMBOL
      : CAD_CURRENCY_SYMBOL;

  const rawPrice = price * priceRate * marginRate;
  let roundedPrice = roundDownToTwoDecimals(rawPrice);

  if (!includeDecimals) {
    roundedPrice = Math.floor(roundedPrice); // Remove decimals if not needed
  }

  const formattedPrice = roundedPrice.toLocaleString(undefined, {
    minimumFractionDigits: includeDecimals ? 2 : 0, // Set minimumFractionDigits accordingly
    maximumFractionDigits: includeDecimals ? 2 : 0, // Set maximumFractionDigits accordingly
  });

  // Adjust return statement based on whether margin is used
  if (useMargin) {
    return `${symbol} ${formattedPrice}`; // Add space if margin is used
  } else {
    return `${symbol}${formattedPrice}`; // No space if margin is not used
  }
};

export const applyMargin = (price, priceRate, currency) => {
  const marginRate = currency.toUpperCase() === CAD_CURRENCY ? 1.01 : 1;

  const rawPrice = Number(price) * priceRate * marginRate;
  const roundedPrice = roundDownToTwoDecimals(rawPrice);

  return roundedPrice.toFixed(2);
};

export const roundDownToTwoDecimals = (number, includeDecimals = true) => {
  // Adjust the number slightly up to account for floating-point errors
  const epsilon = 0.0001;
  number += epsilon;

  let roundedNumber = Math.floor(number * 100) / 100;

  if (!includeDecimals) {
    roundedNumber = Math.floor(roundedNumber); // Remove decimals if not needed
  }

  return roundedNumber;
};
