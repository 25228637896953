export const USD_CURRENCY = 'USD';
export const CAD_CURRENCY = 'CAD';
export const USD_CURRENCY_SYMBOL = 'US$';
export const CAD_CURRENCY_SYMBOL = 'CA$';
export const CURRENCY_COOKIE = 'currency';
export const USD_RATE = 1;

export const formatCurrency = (
  price: number,
  priceRate: number,
  currency: string,
  useMargin: boolean = true
) => {
  let marginRate = 1;
  if (useMargin) {
    marginRate = currency.toUpperCase() === CAD_CURRENCY ? 1.01 : 1;
  }
  const symbol =
    currency.toUpperCase() === USD_CURRENCY
      ? USD_CURRENCY_SYMBOL
      : CAD_CURRENCY_SYMBOL;

  const rawPrice = price * priceRate * marginRate;
  const roundedPrice = roundDownToTwoDecimals(rawPrice);

  const formattedPrice = roundedPrice.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `${symbol} ${formattedPrice}`;
};

export const applyMargin = (
  price: string | number,
  priceRate: number,
  currency: string
) => {
  const marginRate = currency.toUpperCase() === CAD_CURRENCY ? 1.01 : 1;

  const rawPrice = Number(price) * priceRate * marginRate;
  const roundedPrice = roundDownToTwoDecimals(rawPrice);

  return roundedPrice?.toFixed(2);
};

export const roundDownToTwoDecimals = (number) => {
  // Adjust the number slightly up to account for floating-point errors
  const epsilon = 0.0001;
  number += epsilon;

  return Math.floor(number * 100) / 100;
};
