export enum EnumTypeOfUsers {
  All = 'allUsers',
  USER = 'userUsers',
  ADMIN = 'adminUsers',
  AFFILIATE = 'affiliateUsers',
  INDIVIDUAL_AGENT = 'individualAgentUsers',
  AGENCY_MEMBER = 'agencyMemberUsers',
  AGENCIES = 'agencyUsers',
}

export interface IInitialFilterValues {
  search: string;
  currentUsers: EnumTypeOfUsers;
  orderKey: string;
  orderDirection: string;
}

export interface IPagination {
  perPage: number;
  currentPage: number | undefined;
  totalEntries: number | undefined;
}

export enum UserRoles {
  SUPERADMIN = 'SuperAdmin',
  ADMIN = 'Admin',
  USER = 'User',
  AFFILIATE = 'Affiliate',
  Agency = 'Agency',
  AGENT = 'Agent',
  INDIVIDUAL_AGENT = 'Individual Agent',
}

export enum UserStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum EStatusType {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected',
  Removed = 'removed',
}
