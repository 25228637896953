import countries from '@config/supportedCountries.json';
import { DropdownSelect } from './types';

export const industryOptions: DropdownSelect[] = [
  {
    label: 'Content Creator/Influencer',
    value: 'Content Creator/Influencer',
  },
  {
    label: 'Content Publisher or Distributor',
    value: 'Content Publisher or Distributor',
  },
  {
    label: 'Podcast',
    value: 'Podcast',
  },
  {
    label: 'Blog',
    value: 'Blog',
  },
  {
    label: 'Deal Curator',
    value: 'Deal Curator',
  },
  {
    label: 'Offline Travel Agent',
    value: 'Offline Travel Agent',
  },
  {
    label: 'Online Travel Agent',
    value: 'Online Travel Agent',
  },
  {
    label: 'Price-comparison or search site',
    value: 'Price-comparison or search site',
  },
  {
    label: 'Sub-affiliate network',
    value: 'Sub-affiliate network',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const interestedToSellData = ['Tickets', 'Flights', 'Hotels', 'Bundles'];

export const countriesOptions: DropdownSelect[] = countries.map((c) => ({
  label: c.name,
  value: c.isoCode,
}));

export const statusOptions = [
  {
    label: 'Accepted',
    value: 'accepted',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Removed',
    value: 'removed',
  },
];
export const totalDollarValueList = [
  '$0 - $100,000',
  '$101,000 - $250,000',
  '$250,000 - $500,000',
  '$500,000 - $750,000',
  '$750,000 - $1,000,000',
  '$1M - $2M',
  '$2M - $5M',
  '$5M+',
];
