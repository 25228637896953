import routes from '@config/routes';
import instance from '@instance';
import { EStatusType } from '@interfaces/users';
import { AlertToast, AlertTriangle, CheckCircle } from '@v2/ui';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import toast from 'react-hot-toast';

export const useAgencyUpdateStatus = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [success, setSuccess] = useState(false);
  const { data: session } = useSession();

  const router = useRouter();

  const token = session?.user.token.accessToken;

  const updateStatus = async (agencyId: number, status: EStatusType) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await instance.server(
        `${routes.agencyAdmin}/${agencyId}`,
        {
          body: JSON.stringify({ status }),

          method: 'PATCH',
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        return toast.custom(
          (t) => (
            <AlertToast
              {...t}
              title="Oops, something went wrong"
              content={'Please try again in a minute.'}
              icon={<AlertTriangle />}
            />
          ),
          { position: 'top-right' }
        );
      }

      toast.custom(
        (t) => (
          <AlertToast
            {...t}
            title="Success"
            content={'Succesfully updated agency status.'}
            icon={<CheckCircle />}
          />
        ),
        { position: 'top-right' }
      );

      setSuccess(true);

      router.refresh();
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { updateStatus, loading, error, success };
};
