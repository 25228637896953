import { FC } from 'react';
import { SVG, SVGComponentProps } from '../Template';

export const ChevronDown: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG {...props}>
      <path
        d="M6 9L12 15L18 9"
        stroke={props.stroke ?? 'black'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};
